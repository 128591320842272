const ApiUtil = {
  async handleApiCall(response) {
    if (response.ok) {
      const contentType = response.headers.get("content-type")
      if (!!contentType && contentType.indexOf("application/json") !== -1) {
        return response.json()
      }
      return response.text()
    }
    const error = await response.json()
    throw new Error(error.message)
  }
}

export default ApiUtil

import { BASE, CONFIG_SPEC, HA_SPEC, PROFILES } from "../data/DataURLConstants"
import FetchUtil from "../tools/FetchUtil"

export const getVersion = (detailed = false) => {
  let url = BASE.url + "downloads/versions"
  if (detailed) {
    url += "?detailed=true"
  }
  return fetch(url, {
    headers: FetchUtil.headers()
  })
}

export const getAboutEraUrl = () => {
  return CONFIG_SPEC.url + "/about-era"
}

export const getEraInfo = () => {
  return fetch(getAboutEraUrl(), { headers: FetchUtil.headers() })
}

export const updateEraInfo = (body) => {
  return FetchUtil.PUT(getAboutEraUrl(), body)
}

export const getEraConfig = (filterProps) => {
  let url = CONFIG_SPEC.url + "/properties"
  if (filterProps) {
    url = url + "?load-only-config-file=true"
  }
  return fetch(url, {
    headers: FetchUtil.headers()
  })
}

export const getEraFeatureRegistry = () => {
  return fetch(`${CONFIG_SPEC.url}/driver-configs/era_feature_registry`, {
    headers: FetchUtil.headers()
  })
}

export const getSupportedEngines = () => {
  return fetch(`${CONFIG_SPEC.url}/supported-databases?ui=true`, {
    headers: FetchUtil.headers()
  })
}

export const getFeatureConfig = () => {
  return fetch(`${CONFIG_SPEC.url}/properties?pattern=feature`, {
    headers: FetchUtil.headers()
  })
}

export const getCollationProperties = () => {
  return fetch(`${CONFIG_SPEC.url}/driver-configs/sqlserver_collation`, {
    headers: FetchUtil.headers()
  })
}

export const getVirtualIp = (hostname) => {
  let url = `${HA_SPEC.url}/i/vip`
  if (hostname) {
    url += `/fqdn-resolve?fqdn=${hostname}`
    /* NOTE: Response will be a 'json' for this request. Use .json() in Promise then method */
    return fetch(url)
  }
  /* NOTE: Response will be 'text' for this request. Use .text() in Promise then method */
  return fetch(url)
}

export const getEntityCredentials = (type, id) => {
  let url = ""
  switch (type) {
    case "profile":
      url = `${BASE.url}entity_credentials/i/` + id + "?mask-credential=true"
      break
    case "dbserver":
      url =
        `${BASE.url}dbservers/i/` +
        id +
        "/creds?any-status=true&mask-credential=true"
      break
    default:
      break
  }
  return fetch(url, {
    headers: FetchUtil.headers()
  })
}

export const getIpAddresses = (id) => {
  let url = ""
  url = `${PROFILES.url}/` + id + "/get-available-ips"
  return fetch(url, {
    headers: FetchUtil.headers()
  })
}

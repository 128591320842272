import moment from "moment-timezone"
import { OPERATIONS_SPEC, ENTITY_SCHEDULE_SPEC } from "../data/DataURLConstants"
import FetchUtil from "../tools/FetchUtil"

const tz = localStorage.getItem("myTimeZone") || moment.tz.guess()
export const getAllOperations = (
  days,
  system,
  status,
  type,
  owner_id,
  cluster_id,
  limit,
  skip,
  searchStr,
  sortColumn,
  summaryInfo
) => {
  const systemTriggered = system ? "true" : "false"
  let url =
    OPERATIONS_SPEC.url +
    "/short-info?hide-subops=true&user-triggered=true&system-triggered=" +
    systemTriggered +
    "&time-zone=" +
    tz
  url = summaryInfo ? url + "&count-summary=true" : url + "&count-summary=false"
  if (limit) {
    url = url + "&limit=" + limit
  }
  if (skip) {
    url = url + "&skip=" + skip
  }
  if (days) {
    url = url + "&days=" + days
  }
  if (status) {
    url = url + "&status=" + status
  }
  if (type) {
    url = url + "&type=" + type
  }
  if (searchStr) {
    url = url + "&search-text=" + searchStr
  }
  if (owner_id) {
    url = url + "&owner_id=" + owner_id
  }
  if (cluster_id) {
    url = url + "&cluster_id=" + cluster_id
  }
  if (sortColumn) {
    url = url + "&orderBy=" + sortColumn
  }
  return fetch(url, { headers: FetchUtil.headers() })
}

export const getScheduledOperationsList = () => {
  let url =
    OPERATIONS_SPEC.url + "/short-info?status=13&scheduled=true&time-zone=" + tz
  return fetch(url, { headers: FetchUtil.headers() })
}

export const getAllOperationsSummary = (days, upgrade, group_entity) => {
  let url =
    OPERATIONS_SPEC.url +
    "/summary?hide-subops=true&system-triggered=true&user-triggered=true&time-zone=" +
    tz
  if (days) {
    url = url + "&days=" + days
  }
  if (group_entity) {
    url = url + "&group-by-entities=true"
  }
  if (upgrade) {
    return fetch(url, { headers: FetchUtil.headers() })
  }
  return Promise.race([
    fetch(url, { headers: FetchUtil.headers() }),
    new Promise((_, reject) =>
      setTimeout(() => {
        // API reject status
        reject(new Error("timeout"))
      }, 10000)
    )
  ])
}

export const getSupportedOperations = () => {
  return fetch(`${OPERATIONS_SPEC.url}/supported_operations`, {
    headers: FetchUtil.headers()
  })
}

export const stop = (id) => {
  return FetchUtil.PATCH(
    OPERATIONS_SPEC.url + "/" + id + "/manage?action=ABORT"
  )
}

export const resubmit = (id) => {
  return FetchUtil.POST(OPERATIONS_SPEC.url + "/" + id + "/resubmit")
}

export const getOperationDetails = (id, display) => {
  let url = OPERATIONS_SPEC.url + "/" + id + "?time-zone=" + tz
  if (display) {
    url = url + "&display=true"
  }
  return fetch(url, { headers: FetchUtil.headers() })
}

export const getOperationLogs = (id, refresh) => {
  const url = OPERATIONS_SPEC.url + "/" + id + "/debug-info"
  if (refresh) {
    return FetchUtil.PATCH(url)
  }
  return fetch(url, { headers: FetchUtil.headers() })
}

export const getScheduledOperations = (id, status, entityId) => {
  let url =
    OPERATIONS_SPEC.url +
    "/short-info?" +
    "&status=" +
    status +
    "&scheduled=true&time-zone=" +
    tz
  if (id) {
    url = url + "&dbserver-id=" + id
  } else if (entityId) {
    url = url + "&entity-id=" + entityId
  }
  return fetch(url, { headers: FetchUtil.headers() })
}

export const manageSchedule = (id, payload, type) => {
  const url = OPERATIONS_SPEC.url + "/" + id + "/manage-schedule?action=" + type
  if (type === "unset") {
    return FetchUtil.PATCH(url)
  }
  return FetchUtil.PATCH(url, payload)
}

export const abortSchedule = (id) => {
  const url = OPERATIONS_SPEC.url + "/" + id + "/manage?action=ABORT"
  return FetchUtil.PATCH(url)
}

export const getAllScheduledTasks = (days, status) => {
  let url = ENTITY_SCHEDULE_SPEC.url
  if (days) {
    url = url + "?days=" + days
  }
  if (status) {
    url = url + "&status=" + status
  }
  return fetch(url, { headers: FetchUtil.headers() })
}

export const getOperationStatus = (type, status) => {
  let url =
    OPERATIONS_SPEC.url + "/short-info?&status=" + status + "&type=" + type
  return fetch(url, { headers: FetchUtil.headers() })
}

import { BASE } from "../data/DataURLConstants"
import FetchUtil from "../tools/FetchUtil"

export function getIAmPolicyData(cloudType: string) {
  return FetchUtil.REST_CALL(
    "GET",
    `${BASE.url}cloud-profiles/required-configuration?cloud-type=${cloudType}`
  )
}

export function registerCloud(body: any) {
  return FetchUtil.POST(`${BASE.url}cloud-profiles/`, body)
}

export function getCloudProfileData(): Promise<any> {
  return FetchUtil.REST_CALL(
    "GET",
    `${BASE.url}cloud-profiles?fetch-logical-clouds=true`
  )
}

export function getKeyPairs(cloudAccout: string, region: string) {
  return FetchUtil.REST_CALL(
    "GET",
    `${BASE.url}keys/ssh-keys?value-type=ndb-region;cloud-profile-id&value=${region};${cloudAccout}`
  )
}

export function deRegisterDB(id: string, body: any) {
  return FetchUtil.REST_CALL("DELETE", `${BASE.url}dbservers/${id}`, body)
}

export function deRegisterDBURL(id: string, body: any) {
  return `${BASE.url}dbservers/${id}`
}

export function fetchStorageData(cloudType: any) {
  return FetchUtil.REST_CALL(
    "GET",
    `${BASE.url}storage-types?cloud-type=${cloudType}`
  )
}

export function fetchNetworkVPCData(cloudAccountId: string, region: string) {
  return FetchUtil.REST_CALL(
    "GET",
    `${BASE.url}resources/virtual-networks?value-type=cloud-profile-id;ndb-region&value=${cloudAccountId};${region}`
  )
}

export function fetchNetworkSubnetData(cloudAccountId: string, region: string) {
  return FetchUtil.REST_CALL(
    "GET",
    `${BASE.url}resources/subnets?value-type=cloud-profile-id;ndb-region;detailed&value=${cloudAccountId};${region};true`
  )
}

export function fetchSecurityGroupData(cloudAccountId: string, region: string) {
  return FetchUtil.REST_CALL(
    "GET",
    `${BASE.url}resources/network-rules?value-type=cloud-profile-id;ndb-region;exclude-rules&value=${cloudAccountId};${region};true`
  )
}

export function fetchSecurityGroupDetail(
  cloudAccountId: string,
  region: string,
  securityId: string
) {
  return FetchUtil.REST_CALL(
    "GET",
    `${BASE.url}resources/network-rules?value-type=cloud-profile-id;ndb-region;security-rule-id;&value=${cloudAccountId};${region};${securityId}`
  )
}

export function fetchEncryptionKeys(region: string, cloudAccountId: string) {
  return FetchUtil.REST_CALL(
    "GET",
    `${BASE.url}keys/encryption-keys?value-type=ndb-region;cloud-profile-id;encryption-type;&value=${region};${cloudAccountId};symmetric`
  )
}

export function fetchDBParamProfiles(dbParamId: string, computeId: string) {
  return FetchUtil.REST_CALL(
    "GET",
    `${BASE.url}profiles?value-type=id&value=${dbParamId}&compute-profile-id=${computeId}`
  )
}

export function fetchAZData(
  cloudAccountId: string,
  region: string,
  instanceType: string
) {
  return FetchUtil.REST_CALL(
    "GET",
    `${BASE.url}instances/types?value-type=cloud-profile-id;ndb-region;detailed;instance-types&value=${cloudAccountId};${region};true;${instanceType}`
  )
}

export function fetchSLAData() {
  return FetchUtil.REST_CALL("GET", `${BASE.url}slas`)
}

/**
 * Function to call /price-estimate API
 * /era/1.0/databases/provision/price-estimate
 */
export function fetchCostEstimate(payload: any, headers: any) {
  return FetchUtil.REST_CALL(
    "POST",
    `${BASE.url}databases/provision/price-estimate`,
    payload,
    null,
    null,
    headers
  )
}

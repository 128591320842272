import { Redirect } from "react-router"
import { Route } from "react-router-dom"

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const isAuthenticated =
          !!localStorage.getItem("authorized") ||
          !localStorage.getItem("logout")
        if (isAuthenticated) {
          return <Component {...props} />
        } else {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        }
      }}
    />
  )
}

export default PrivateRoute
